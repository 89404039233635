import React, {useState, useEffect} from "react";
import { useGeolocated } from "react-geolocated";

const Demo = () => {
    const [lastUpdateDate, setLastUpdateDate] = useState(false);
    const [placeImgSrc, setPlaceImgSrc] = useState(false);
    const { coords, isGeolocationAvailable, isGeolocationEnabled, timestamp } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
            watchPosition:true,
        });
    useEffect(()=>{
        if(timestamp){
            let dateFromTimestamp = new Date(timestamp).toString();
            if(lastUpdateDate != dateFromTimestamp){
                setLastUpdateDate(dateFromTimestamp);
            }
        }
    },[timestamp])
    useEffect(()=>{
        if(coords){
            let imgSrc = "https://maps.googleapis.com/maps/api/staticmap?center="+coords.latitude+","+coords.longitude+"&zoom=12&size=400x400";
            if(placeImgSrc != imgSrc){
                setPlaceImgSrc(imgSrc);
            }
        }
    },[coords])
    return !isGeolocationAvailable ? (
        <div>Your browser does not support Geolocation</div>
    ) : !isGeolocationEnabled ? (
        <div>Geolocation is not enabled</div>
    ) : coords ? (
        <div>
            <div>{coords.latitude}, {coords.longitude}, {coords.altitude}, {timestamp}, {lastUpdateDate}</div>
        </div>
    ) : (
        <div>Getting the location data&hellip; </div>
    );
}
export default Demo;